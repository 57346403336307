import React from "react";

const LocationIcon = ({
  width = "24",
  height = "24",
  fill = "#262626",
  className = "",
}) => (
  <span className={className}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0297 6.77578C19.5884 5.75831 18.9523 4.83708 18.157 4.06406C17.3592 3.28406 16.4186 2.66498 15.3867 2.24062C14.3133 1.79531 13.1742 1.57031 12 1.57031C10.8258 1.57031 9.68672 1.79531 8.61328 2.23828C7.575 2.66719 6.64453 3.28125 5.84297 4.06172C5.04826 4.83517 4.41215 5.7563 3.97031 6.77344C3.51328 7.82812 3.28125 8.94844 3.28125 10.1016C3.28125 11.7563 3.67734 13.4039 4.45547 14.993C5.08125 16.2703 5.95547 17.5148 7.05703 18.6984C8.93906 20.7188 10.9125 21.9539 11.4727 22.2844C11.6317 22.3783 11.813 22.4277 11.9977 22.4273C12.1805 22.4273 12.3609 22.3805 12.5227 22.2844C13.0828 21.9539 15.0563 20.7188 16.9383 18.6984C18.0398 17.5172 18.9141 16.2703 19.5398 14.993C20.3227 13.4062 20.7188 11.7609 20.7188 10.1039C20.7188 8.95078 20.4867 7.83047 20.0297 6.77578ZM12 20.6297C10.4555 19.6477 4.96875 15.7594 4.96875 10.1039C4.96875 8.27813 5.69766 6.5625 7.02188 5.26875C8.35078 3.97266 10.118 3.25781 12 3.25781C13.882 3.25781 15.6492 3.97266 16.9781 5.27109C18.3023 6.5625 19.0312 8.27813 19.0312 10.1039C19.0312 15.7594 13.5445 19.6477 12 20.6297ZM12 6.16406C9.72188 6.16406 7.875 8.01094 7.875 10.2891C7.875 12.5672 9.72188 14.4141 12 14.4141C14.2781 14.4141 16.125 12.5672 16.125 10.2891C16.125 8.01094 14.2781 6.16406 12 6.16406ZM13.8563 12.1453C13.6128 12.3895 13.3234 12.5831 13.0049 12.715C12.6863 12.847 12.3448 12.9146 12 12.9141C11.2992 12.9141 10.6406 12.6398 10.1437 12.1453C9.89959 11.9018 9.70597 11.6125 9.57404 11.2939C9.44211 10.9754 9.37446 10.6339 9.375 10.2891C9.375 9.58828 9.64922 8.92969 10.1437 8.43281C10.6406 7.93594 11.2992 7.66406 12 7.66406C12.7008 7.66406 13.3594 7.93594 13.8563 8.43281C14.3531 8.92969 14.625 9.58828 14.625 10.2891C14.625 10.9898 14.3531 11.6484 13.8563 12.1453Z"
        fill={fill}
      />
    </svg>
  </span>
);

export default LocationIcon;
